import { MdErrorOutline } from '@react-icons/all-files/md/MdErrorOutline';
import { TiTick } from '@react-icons/all-files/ti/TiTick';
import React, { useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';

import compact from '../../utils/helpers/compact';
import { removeHtmlTags } from '../../utils/parseHtml';
import { tw } from '../../utils/tailwind';
import { Flex, Grid } from '../Core';
import FormField from '../Form/FormField';
import Input from '../Form/Input';
import { IQuestion } from '../Newsletter/Newsletter';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

declare let window: CustomWindow;

export type ContactFormData = {
  [key: string]: string;
  'last-name': string;
  'first-name': string;
  email: string;
  phone: string;
  message: string;
  services: string;
  centres: string;
  organization: string;
  'alternative-email': string;
};

interface IContactFormProps {
  feedbackMessages: {
    error: string | undefined;
    success: string | undefined;
  };
  submitButtonLabel: string | null;
  questions: IQuestion[];
  centres: Queries.PageContactQuery['allWpCentre']['nodes'];
  services:Queries.PageContactQuery['allWpService']['nodes'];
  defaultService: string | undefined;
  defaultCentre: string | undefined;
}

const ContactForm: FCWithClassName<IContactFormProps> = (props) => {
  const {
    submitButtonLabel,
    feedbackMessages,
    defaultCentre,
    defaultService,
    questions,
    centres,
    services,
    ...rest
  } = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormData>();
  const onSubmit = (formData: ContactFormData) => {
    const encode = (formDataToEncode: ContactFormData) => {
      const message = compact(
        Object.keys(formDataToEncode).map(
          (key) => {
            if (key === 'alternative-email') return null;
            const displayedKey = key;
            const displayedValue = formDataToEncode[key];
            const label = `${displayedKey} :`;
            return renderToStaticMarkup(
              <p>
                <strong>{label}</strong> {displayedValue}
              </p>
            );
          }
        )
      ).join('');
      const centreName = centres.find(
        (centre) => centre.slug === formData.centres
      )?.coordonnees.nomLong;
      return {
        alternativeEmail: formData['alternative-email'], // this is the honeypot field for antispam
        clientCentre: centreName,
        clientEmail: formData.email,
        clientFirstName: formData.firstName,
        clientLastName: formData.name,
        clientMessage: formData.message,
        clientPhone: formData.phone,
        clientService: formData.services,
        message,
        source: 'Formulaire de contact', // if updated, change this in functions.php as well
      };
    };
    fetch(process.env.GATSBY_WORDPRESS_URL + '/wp-json/contact/v1/send', {
      body: JSON.stringify(encode(formData)),
      headers: { 'Content-Type': 'text/html' },
      method: 'POST',
    })
      .then(() => setShowSuccess(true))
      .catch(() => setShowError(true));
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'formSubmission',
        formType: 'Contact us',
      });
    }
  };
  const displayFeedbackMessage = () => {
    if (showSuccess) {
      return (
        <Flex direction="column" align="center" className="mb-16">
          <TiTick className="w-20 h-20 mb-4 text-green-500" />
          <Text variant={TEXT_STYLES.H4} className="mx-auto text-center">
            {feedbackMessages.success}
          </Text>
        </Flex>
      );
    } else if (showError) {
      return (
        <Flex direction="column" align="center" className="mb-16">
          <MdErrorOutline className="w-20 h-20 mb-4 text-orange-500" />
          <Text variant={TEXT_STYLES.H4} className="mx-auto text-center">
            {feedbackMessages.error}
          </Text>
        </Flex>
      );
    } else {
      return null;
    }
  };
  const botFieldLabel = 'Alternative email'; // this is a honeypot field
  const questionsWithSelectOptions = questions.map((question) => {
    if (question.name === 'centres') {
      const options = centres?.map((centre) => ({
        checked: centre.slug === defaultCentre,
        label: centre.title,
        name: centre.slug,
      }));
      return { ...question, options };
    } else if (question.name === 'services') {
      const options = services?.map((service) => ({
        checked: service.slug === defaultService,
        label: service.title,
        name: service.slug,
      }));
      return { ...question, options };
    } else {
      return question;
    }
  });
  return (
    displayFeedbackMessage() || (
      <form
        className="relative w-full pb-16 m-auto lg:w-4/5 lg:pb-0"
        onSubmit={handleSubmit(onSubmit)}
        name="Contact Form"
        method="POST"
        {...rest}
      >
        <Grid className="grid-cols-1 gap-y-10 gap-x-10 lg:gap-x-10 lg:grid-cols-2">
          {questionsWithSelectOptions.map((question) => {
            return (
              <FormField
                type={question.type}
                key={question.name}
                name={question.name}
                placeholder={removeHtmlTags(question.label)}
                wrapperClassName={tw(
                  ['services', 'message'].includes(question.name)
                    ? 'lg:col-span-2'
                    : ''
                )}
                options={question.options}
                register={register as unknown as UseFormRegister<FieldValues>}
                errors={errors[question.name]}
                required={question.required}
                rows={question.rows}
              />
            );
          })}

          <ThemedButton
            type="submit"
            className="!w-fit"
            isLoading={isSubmitting}
          >
            {submitButtonLabel}
          </ThemedButton>
          <Input
            name="alternative-email"
            placeholder={botFieldLabel}
            className="form--input"
            autocomplete="off"
            aria-hidden="true"
            type="text"
            {...register('alternative-email')}
          />
        </Grid>
      </form>
    )
  );
};

export default ContactForm;
