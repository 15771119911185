import { graphql, PageProps } from 'gatsby';
import React from 'react';

import CentreCard from '../../components/CentreCard';
import ContactForm from '../../components/ContactForm';
import { Flex, Grid, Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import StyledLink from '../../components/StyledLink';
import Text from '../../components/Text';
import { BUTTON_STYLES } from '../../constants/ui';
import { INavigationProps } from '../../types/wordpress/blocks';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';

type IContactPageTemplate = 
  PageProps<Queries.PageContactQuery, IPageContext, Pick<INavigationProps, 'services' | 'centre'>>;

const ContactPageTemplate: FCWithClassName<IContactPageTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuContact' in data.wpPage.template ? data.wpPage.template.contenuContact : null;
  const services = data.allWpService.nodes;
  const centres = data.allWpCentre.nodes;
  const defaultService = props.location?.state?.services?.[0];
  const defaultCentre = props.location?.state?.centre;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const serializedContactFormQuestions =
    pageTemplateData.contactSectionContacter.questions?.map(
      (question) => {
        const options = question.choixDisponibles?.map((choice) => ({
          checked: choice.checked,
          label: choice.label,
          name: choice.name,
        }));
        return {
          label: question.label,
          name: question.slug,
          ...(options ? { options } : {}),
          required: question.obligatoire,
          ...(question.rows ? { rows: question.rows } : {}),
          ...(question.toggletext
            ? { toggleButtonText: question.toggletext }
            : {}),
          type: question.questionType as QUESTION_TYPE,
        };
      }
    );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.hero.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap lg:space-y-gap-xl" justify="center">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={pageData.hero.couleurTitre}
          heading={pageData.hero.titre}
          tagline={pageData.hero.tagline}
          backgroundImage={heroImageWithArtDirection}
        />
        <SectionWithTitle
          heading={
            pageTemplateData.contactSectionContacter.titre ?? undefined
          }
        >
          <Flex direction={{ base: 'column', lg: 'row' }} className="w-full">
            <ContactForm
              feedbackMessages={{
                error: pageTemplateData.contactSectionContacter.errorMsg,
                success:
                  pageTemplateData.contactSectionContacter.successMsg,
              }}
              questions={serializedContactFormQuestions}
              centres={centres}
              services={services}
              defaultCentre={defaultCentre}
              defaultService={defaultService}
              submitButtonLabel={
                pageTemplateData.contactSectionContacter
                  .texteBoutonValidation
              }
            />
            <Flex direction="column" className="px-0 lg:px-16">
              <Text className="mb-4">
                {pageTemplateData.contactSectionContacter.text || ''}
              </Text>
              <Text>
                {pageTemplateData.contactSectionContacter
                  .phraseEmailDeContact || ''}
              </Text>
              <StyledLink
                shouldObfuscate
                isEmail
                to={
                  pageTemplateData.contactSectionContacter.emailDeContact
                }
              >
                <Text>
                  {pageTemplateData.contactSectionContacter
                    .emailDeContact || ''}
                </Text>
              </StyledLink>
            </Flex>
          </Flex>
        </SectionWithTitle>
        <SectionWithTitle
          heading={
            pageTemplateData.contactSectionCentres.titre ?? undefined
          }
          text={
            pageTemplateData.contactSectionCentres.description ??
            undefined
          }
        >
          <Grid className="gap-8 grid-cols-[repeat(auto-fit,minmax(250px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] xxl:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] w-full justify-items-center">
            {centres?.map((centre) => {
              if (!centre) return undefined;
              const goToCentrePageButtonProps = {
                internalLink: { id: centre.id, uri: centre.uri },
                style: BUTTON_STYLES.SOLID,
                titre:
                  pageTemplateData.contactSectionCentres.centrePageLabel,
              };
              const itineraryButtonProps = {
                externalLink: `https://www.google.com/maps/dir/?api=1&destination=${centre.coordonnees.gps}`,
                style: BUTTON_STYLES.OUTLINE,
                titre:
                  pageTemplateData.contactSectionCentres
                    .destinationLabel,
              };
              return (
                <CentreCard
                  ctas={[goToCentrePageButtonProps, itineraryButtonProps]}
                  key={centre.id}
                  address={centre.coordonnees.adresse}
                  email={centre.coordonnees.email}
                  facebook={centre.coordonnees.facebook}
                  gps={centre.coordonnees.gps}
                  image={serializeImageForImageComponent({
                    image: centre.featuredImage?.node,
                  })}
                  instagram={centre.coordonnees.instagram}
                  phone={centre.coordonnees.telephone}
                  title={`Uperform ${centre.title}`}
                />
              );
            })}
          </Grid>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default ContactPageTemplate;

export const query = graphql`
  query PageContact($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      link
      seo {
        ...SEOFragment
      }
      language {
        locale
        slug
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageContact {
          contenuContact {
            contactSectionCentres {
              centrePageLabel
              description
              destinationLabel
              titre
            }
            contactSectionContacter {
              emailDeContact
              errorMsg
              phraseEmailDeContact
              questions {
                ... on WpTemplate_PageContact_Contenucontact_ContactSectionContacter_Questions_Question {
                  label
                  obligatoire
                  questionType
                  rows
                  slug
                  toggletext
                  choixDisponibles {
                    checked
                    label
                    name
                  }
                }
              }
              successMsg
              text
              texteBoutonValidation
              titre
            }
            contactSectionHero {
              tagline
              titre
            }
          }
        }
      }
    }
    allWpCentre(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        id
        slug
        uri
        featuredImage {
          node {
            ...MediaItemFragment
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  
                  layout: CONSTRAINED
                )
              }
              publicURL
            }
          }
        }
        coordonnees {
          adresse
          email
          facebook
          gps
          instagram
          telephone
          nomLong
        }
      }
    }
    allWpService(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        slug
        title
      }
    }
  }
`;
